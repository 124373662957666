<template>
  <div class="card">
    <div class="card-body">
      <h4
        class="card-title"
        :class="
          $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : 'txt-left'
        "
      >
        {{ label }}
      </h4>
      <div class="col-12 d-flex align-items-center" style="padding: 0">
        <input
          id="check1"
          name="check"
          value="my_nurse"
          class="text-lowercase"
          :class="row.disabled ? 'disabled' : ''"
          :type="type"
          v-model="row.input"
          @paste="onPaste"
          :disabled="row.disabled"
          v-on:change="onChange"
        
        />
        <label for="check1" class="m-2">My Nursery</label>
        <!--  @keydown.space.prevent  -->
      </div>
      <div class="col-12 d-flex align-items-center" style="padding: 0">
        <input
          id="check"
          name="check"
          value="international"
          class="text-lowercase"
          :class="row.disabled ? 'disabled' : ''"
          :type="type"
          v-model="row.input"
          @paste="onPaste"
          :disabled="row.disabled"
          v-on:change="onChange"
        />
        <label for="check" class="m-2">International</label>
        <!--  @keydown.space.prevent  -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardCertificate",
  props: ["label", "type", "input", "disabled" , ],
  data() {
    return {
      row: {
        input: this.$props.input,
        disabled: this.$props.disabled,
        // selected: this.props.selected,
      },
    };
  },
  mounted() {},
  methods: {
    onChange() {
      const data = {
        input: this.row.input,
      };
      this.$emit("inputChange", data);
    },
    onPaste() {
      let str = this.row.input;
      this.preventSpace(str);
    },
    preventSpace(str) {
      if (!str) {
        let str = this.row.input;
        this.row.input = str.replace(/\s+/g, "-");
      } else {
        this.row.input = str.replace(/\s+/g, "-");
      }
    },
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input.disabled {
  background: #eee;
}
</style>